import { useEffect, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, IconButton, LinearProgress, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';

import Footer from './Footer';

// Importación de fuentes de Roboto
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Importación de imagen de la barra de aplicaciones
import appBarImage from '../assets/LogoDimobaCorpo_Blanco.png'
import theme from '../theme';
import fetchAsync from '../fetchAsync';

// Lista de elementos del menú
const menuItems = [
  { id: 1, title: 'Consultar clientes', to: '/' },
  { id: 2, title: 'Consultar Synced Data', to: '/syncedData' },
]

function Root() {
  // Manejo del estado del botón seleccionado
  const handleButtonClick = (id) => {
    setSelectedButtonIndex(id);
  };

  // Manejo del menú desplegable en dispositivos móviles
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  // Obtener la ubicación actual de la página
  const location = useLocation();

  // Actualizar el botón seleccionado cuando cambia la ubicación
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setSelectedButtonIndex(1);
        break;
      case '/syncedData':
        setSelectedButtonIndex(2);
        break;
      default:
        setSelectedButtonIndex(-1);
        break;
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchAsync("fetchAbilities", 'GET', []);
        data.forEach(element => {
          if (element.tool_code === "SUPERADMIN") {
            setIsAdmin(true);
          }
          if (element.tool_code === "SUPERADMIN" || element.tool_code === "CUSTOMER_COMPARATOR") {
            setHasAccess(true);
          }
        });
        setIsLoading(false);

      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [])

  return (
    <>
      {/* Barra de aplicaciones */}
      <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo en la barra de aplicaciones */}
            <IconButton
              component={Link}
              to="/"
              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            >
              <img src={appBarImage} alt="Logo" style={{ height: '100%', width: 'auto' }} />
            </IconButton>

            {/* Menú desplegable en dispositivos móviles */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="Dropdown menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {/* Elementos del menú */}
                {menuItems.map((menuItem) => (
                  menuItem.id === 2 && !isAdmin ? (
                    null
                  ) : (
                    <MenuItem key={menuItem.id} component={Link} to={menuItem.to} selected={location.pathname === menuItem.to} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{menuItem.title}</Typography>
                    </MenuItem>
                  )
                ))}

              </Menu>
            </Box>

            {/* Logo en la barra de aplicaciones en dispositivos móviles */}
            <IconButton
              component={Link}
              to="/"
              sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
            >
              <img src={appBarImage} alt="Logo" style={{ height: '100%', width: 'auto' }} />
            </IconButton>

            {/* Elementos del menú en la barra de aplicaciones */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {menuItems.map((menuItem) => (
                menuItem.id === 2 && !isAdmin ? null : (
                  <Button
                    key={menuItem.id}
                    component={Link} to={menuItem.to}
                    sx={{
                      borderRadius: 0,
                      my: 2,
                      color: selectedButtonIndex === menuItem.id ? 'white' : 'lightgrey',
                      display: 'block',
                      borderBottom: selectedButtonIndex === menuItem.id ? '2px solid black' : null
                    }}
                    onClick={() => handleButtonClick(menuItem.id)}
                    variant="text"
                    color="primary"
                  >
                    {menuItem.title}
                  </Button>
                )
              ))}

            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Contenido principal */}
      <Container maxWidth="xl">
        <Toolbar />
        {isLoading ? (
          <div>
            <Typography textAlign="center">
              Comprobando permisos de acceso...
            </Typography>
            {isLoading && <LinearProgress />}
          </div>
        ) : (
          !hasAccess ? (
            <Typography textAlign="center">
              403. Acceso denegado.
            </Typography>
          ) : (
            <Outlet />
          )
        )}

      </Container>

      {/* Pie de página */}
      <Footer />
    </>
  );
}

export default Root;
