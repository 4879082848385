import { Box, Container, IconButton } from '@mui/material';
import powered_by_idi from '../assets/powered_by_idi.png';

function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 'auto',
      }}
    >

      <Box sx={{ backgroundColor: 'primary.main', width: '100%' }}>
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'right', flexDirection: 'row' }}>
            <IconButton
              component="a"
              href="https://idi28.com/"
              target="_blank"
              sx={{ display: 'flex', mr: 1 }}
            >
              <img src={powered_by_idi} alt="Logo" style={{ height: '100%', width: 'auto' }} />
            </IconButton>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
