const baseUrl = process.env.REACT_APP_BASE_URL;
const bearToken = process.env.REACT_APP_TOKEN;

const baseUrlAbortControllers = {};

const fetchAsync = async (url, method, getParams) => {
    try {
        const urlSearch = new URL(
            baseUrl + url
        );

        const baseUrlKey = urlSearch.origin + urlSearch.pathname;
        if (baseUrlAbortControllers[baseUrlKey]) {
            baseUrlAbortControllers[baseUrlKey].abort();
        }

        const controller = new AbortController();
        baseUrlAbortControllers[baseUrlKey] = controller;

        if (Object.keys(getParams).length !== 0) {
            for (const key in getParams) {
                if (Array.isArray(getParams[key])) {
                    urlSearch.searchParams.set(key, JSON.stringify(getParams[key]));
                } else {
                    urlSearch.searchParams.set(key, getParams[key]);
                }
            }
        }

        const response = await fetch(urlSearch, {
            method: method,
            headers: {
                'Authorization': `Bearer ${bearToken}`,
                'Content-Type': 'application/json',
            },
            signal: controller.signal,
            credentials: 'include', // Incluir las cookies en la solicitud
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw errorData;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export default fetchAsync;
