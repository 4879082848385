import React, { useState, useEffect, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import fetchAsync from '../../fetchAsync'
import { Box, Button, Modal, Typography, Paper } from '@mui/material'
import { modalStyle } from '../../styles/styles'
import moment from 'moment'

const IntegrationDataTable = () => {
    useEffect(() => {
        document.title = 'Clientes';
      }, []);
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [serverErrorCode, setServerErrorCode] = useState("");
    const [serverErrorMsg, setServerErrorMsg] = useState("");

    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });

    const [showModal, setShowModal] = useState(false);
    const [syncedData, setSyncedData] = useState([]);
    const [idCliente, setIdCliente] = useState("");

    const statusList = ['Cliente correcto', "Codigo_exportacion vacio", "CIF vacio", "CIF duplicado", "CIF erroneo", "No existe en Poseidon", "Discrepancia nombres"];

    const handleShowModal = (value) => {
        setSyncedData(value);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSyncedData([]);
        setIdCliente("");
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsRefetching(!response.length);

            const getParams = {
                start: pagination.pageIndex,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? [],
            };

            try {
                const data = await fetchAsync("fetchClient", 'GET', getParams);
                setResponse(data[0].clients);
                setRowCount(data[0].totalRowCount);
                setIsError(false);
                setIsLoading(false);
                setIsRefetching(false);
                setServerErrorCode("")
                setServerErrorMsg("")

            } catch (error) {
                if (error.name !== 'AbortError') {
                    setServerErrorCode(error.code ?? "")
                    setServerErrorMsg(error.error ?? "")
                    setIsError(true);
                    setIsLoading(false);
                    setIsRefetching(false);
                }
            }
        };
        setIsLoading(true);
        fetchData();
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'client.idcliente',
                header: 'Id Cliente',
                size: 30,
            },
            {
                accessorKey: 'client.exportacion_valor.codigo_exportacion',
                header: 'Código exportacion',
                size: 30,
                enableSorting: false,
            },
            /*Fecha para el modal
            {
                accessorKey: 'client.fcreacion',
                header: 'Fecha',
                Cell: ({ row }) => (
                    < Typography >
                        {moment(row.original.client.fcreacion ?? "").format('DD/MM/YYYY HH:mm:ss')}
                    </Typography >

                ),
                size: 50
            },
            */
            {
                accessorKey: 'client.cif',
                header: 'CIF',
                size: 50,
                enableSorting: false,
            },
            {
                accessorKey: 'client.nombre',
                header: 'Nombre Beta10',
            },
            {
                accessorKey: 'client.customer_poseidon_by_cif.name',
                header: 'Nombre poseidon',
                enableSorting: false,
            },
            {
                accessorKey: 'errorMsg',
                header: 'Estado',
                enableSorting: false,
                Cell: ({ row }) => (
                    <div
                        style={{
                            backgroundColor: row.original.status ?? false ? 'green' : 'red',
                            padding: '5px',
                            color: 'white',
                            borderRadius: '4px',
                        }}
                    >
                        {row.original.errorMsg ?? "null"}
                    </div>
                ),
                size: 50,
                filterVariant: 'select',

                filterSelectOptions: statusList,
            },
            {
                accessorKey: 'syncedData',
                header: 'Acción',
                size: 50,
                Cell: ({ row }) => (
                    <Button variant="contained" color="primary" onClick={() => {
                        handleShowModal(row.original.syncedData ?? [])
                        setIdCliente(row.original.client.idcliente)
                    }}>
                        Synced Data
                    </Button >
                ),
                enableSorting: false,
                enableColumnFilter: false,
            },
        ],
        []
    );

    return (
        <>
            <Box sx={{ display: 'inline-block', width: '100%' }}>
                {serverErrorMsg !== "" ? (
                    <Box
                        style={{
                            fontSize: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '40vh', // Opcional: para centrar verticalmente en la pantalla
                        }}
                    >
                        <Typography variant="h1" sx={{ fontSize: '2rem' }}>
                            Error: {serverErrorCode}
                        </Typography>
                        <Typography variant="h1" sx={{ fontSize: '2rem' }}>
                            , {serverErrorMsg}
                        </Typography>
                    </Box>

                ) : (
                    <MaterialReactTable
                        columns={columns}
                        data={response}
                        initialState={{
                            density: 'compact',
                            pagination: { pageSize: 25, pageIndex: 0 },
                            columnVisibility: {
                                'client.exportacion_valor.codigo_exportacion': false,
                            }
                        }}
                        localization={MRT_Localization_ES}
                        muiTableBodyProps={{
                            sx: () => ({
                                '& tr:nth-of-type(odd)': {},
                            }),
                        }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        manualFiltering
                        manualPagination
                        manualSorting
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error loading data',
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                        }}
                    />)}
            </Box>
            <Modal open={showModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <>
                        <Typography variant="h5" component="h5">
                            SyncedData:
                        </Typography>
                        <Typography variant="h6" component="h6">
                            ID cliente: {idCliente}
                        </Typography>
                        <Box sx={{ paddingTop: '2em', paddingBottom: '2em' }}>

                            <Paper style={{ maxHeight: '100%', overflow: 'auto', paddingRight: '1.5em', }}>
                                <ul>
                                    {syncedData.length !== 0 ? (
                                        <>

                                            {syncedData.map(item => (
                                                <>
                                                    <li>
                                                        {JSON.parse(item.metadata).metadata === false ? (
                                                            <Typography variant="body1" component="p">
                                                                {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}: Error exportacion valor
                                                            </Typography>
                                                        ) : (
                                                            JSON.parse(item.metadata).metadata.errorCode === "200" || JSON.parse(item.metadata).metadata.errorCode === "201" ? (
                                                                <Typography variant="body1" component="p">
                                                                    {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}: Cliente correcto
                                                                </Typography>
                                                            ) : (
                                                                JSON.parse(item.metadata).metadata.idexportacion_valor ? (
                                                                    <Typography variant="body1" component="p">
                                                                        {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}: Exportación valor creada correctamente
                                                                    </Typography >
                                                                ) : (
                                                                    <Typography variant="body1" component="p">
                                                                        {moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}: {JSON.parse(item.metadata).metadata.errorMessage}
                                                                    </Typography >
                                                                )
                                                            )
                                                        )}
                                                    </li>
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <li>
                                            <Typography variant="body1" component="p">
                                                No tiene SyncedData
                                            </Typography>
                                        </li>
                                    )}

                                </ul>
                            </Paper>
                        </Box>

                    </>

                    <Button variant="contained" color="primary" onClick={handleCloseModal}  >
                        Cerrar
                    </Button>
                </Box>
            </Modal >

        </>
    );

}

export default IntegrationDataTable