export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
    backgroundColor: 'white',
    borderRadius: '5px',
    p: 4,
    display: 'flex',
    flexDirection: 'column', // Ajustar el contenido en columna
}
 