import React, { useState, useEffect, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import fetchAsync from '../../fetchAsync'
import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { modalStyle } from '../../styles/styles'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const IntegrationDataTable = () => {
    useEffect(() => {
        document.title = 'Synced Data';
      }, []);
    const [response, setResponse] = useState([]);
    const [serverErrorCode, setServerErrorCode] = useState("");
    const [serverErrorMsg, setServerErrorMsg] = useState("");


    const [showModal, setShowModal] = useState(false);
    const [selectedError, setSelectedError] = useState(null);
    const [selectedTitleError, setTitleSelectedError] = useState('');
    const [selectedMetadataError, setSelectedMetadataError] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });

    const handleShowModal = (value) => {
        if (value.endpoint !== 'exportacion_valor') {
            if (value.metadata.errorCode === "200" || value.metadata.errorCode === "201") {
                setTitleSelectedError('No ha habido ningun problema');
                setSelectedMetadataError(value.metadata.data);

            } else {
                setTitleSelectedError('Ha ocurrido un error');
                setSelectedError(value.metadata.errorMessage);
            }
        } else {
            if (value.metadata === false) {
                setSelectedError('False');
                setTitleSelectedError('Ha ocurrido un error');
            } else {
                setSelectedError("idexportacion_valor-> " + value.metadata.idexportacion_valor);
                setTitleSelectedError('No ha habido ningun problema');
            }
        }
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMetadataError(null)
        setTitleSelectedError("");
        setSelectedError(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsRefetching(!response.length);

            const getParams = {
                start: pagination.pageIndex,
                size: pagination.pageSize,
                filters: columnFilters ?? [],
                globalFilter: globalFilter ?? '',
                sorting: sorting ?? [],
            };

            try {
                const data = await fetchAsync("fetchSyncedData", 'GET', getParams);
                setResponse(data.syncedData);
                setRowCount(data.totalRowCount);
                setIsError(false);
                setIsLoading(false);
                setIsRefetching(false);
                setServerErrorCode("")
                setServerErrorMsg("")

            } catch (error) {
                if (error.name !== 'AbortError') {
                    setServerErrorCode(error.code ?? "")
                    setServerErrorMsg(error.error ?? "")

                    setIsError(true);
                    setIsLoading(false);
                    setIsRefetching(false);
                }
            }
        };
        setIsLoading(true);
        fetchData();
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'idSyncedData',
                header: 'Id ',
                size: 30,
            },
            {
                accessorKey: 'idClient',
                header: 'Id Cli',
                size: 30,
            },
            {
                accessorKey: 'updated_at',
                header: 'Fecha',
                type: 'date',
                Cell: ({ row }) => (
                    < Typography >
                        {moment(row.original.updated_at).format("DD/MM/YYYY")}
                    </Typography >

                ),
                size: 50,
                Filter: ({ column }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="DD-MM-YYYY"
                            disableFuture
                            onChange={(newValue) => {
                                column.setFilterValue(newValue);
                            }}
                            slotProps={{
                                textField: {
                                    sx: { minWidth: '100px' },
                                    variant: 'standard',
                                },
                            }}
                            value={column.getFilterValue()}
                        />
                    </LocalizationProvider>
                ),
            },
            {
                accessorKey: 'client.nombre',
                header: 'Nombre',
                enableSorting: false,
            },
            {
                accessorKey: 'client.cif',
                header: 'CIF',
                size: 50,
                enableSorting: false,
            },
            {
                accessorKey: 'metadata.data.COD_CLI',
                header: 'COD_CLI',
                size: 60,
                Cell: ({ row }) => (
                    <>
                        <Typography>
                            {row.original.metadata.data ? row.original.metadata.data.COD_CLI : "null"}
                        </Typography>
                    </>
                ),
                enableSorting: false,
            },
            {
                accessorKey: 'method',
                header: 'Metodo',
                size: 45,
                enableSorting: false,
                filterVariant: 'select',
                filterSelectOptions: ['PUT', "POST"],
            },
            {
                accessorKey: 'endpoint',
                header: 'Endpoint',
                enableSorting: false,
            },
            {
                accessorKey: 'metadata.errorCode',
                header: 'Error Code',
                size: 60,
                Cell: ({ row }) => (
                    <Typography>
                        {(row.original.metadata.errorCode ?? (row.original.metadata.idexportacion_valor ? "201" : "null"))}
                    </Typography>

                ),
                enableSorting: false,
                filterVariant: 'select',
                filterSelectOptions: ['200', "201", "400"],
            },
            {
                accessorKey: 'metadata',
                header: 'Accion',
                size: 100,
                Cell: ({ row }) => (
                    <Button variant="contained" color="primary" onClick={() => {
                        handleShowModal(row.original)
                    }}>
                        Ver estado
                    </Button>
                ),
                enableSorting: false,
                enableColumnFilter: false,
            },
        ],
        []
    );

    return (
        <>
            <Box sx={{ display: 'inline-block', width: '100%' }}>
                {serverErrorMsg !== "" ? (
                    <Box
                        style={{
                            fontSize: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '40vh', // Opcional: para centrar verticalmente en la pantalla
                        }}
                    >
                        <Typography variant="h1" sx={{ fontSize: '2rem' }}>
                            Error: {serverErrorCode}
                        </Typography>
                        <Typography variant="h1" sx={{ fontSize: '2rem' }}>
                            , {serverErrorMsg}
                        </Typography>
                    </Box>

                ) : (
                    <MaterialReactTable
                        columns={columns}
                        data={response}
                        initialState={{
                            density: 'compact',
                            pagination: { pageSize: 25 },
                            columnVisibility: {
                                'idClient': false,
                                'idSyncedData': false,
                            }
                        }}
                        localization={MRT_Localization_ES}
                        muiTableBodyProps={{
                            sx: () => ({
                                '& tr:nth-of-type(odd)': {},
                            }),
                        }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                        manualFiltering
                        manualPagination
                        manualSorting
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: 'Error loading data',
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                        }}
                    />
                )}

            </Box>

            <Modal open={showModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h6" sx={{ paddingBottom: '1em' }}>
                        {selectedTitleError}
                    </Typography>
                    {selectedError &&
                        <Typography variant="body1" component="p" sx={{ textAlign: 'justify', paddingBottom: '1em' }}>
                            Estado: {selectedError}
                        </Typography>
                    }

                    {selectedMetadataError && (
                        <>
                            <Paper style={{ maxHeight: '100%', overflow: 'auto' }}>
                                <ul>
                                    {Object.keys(selectedMetadataError).map((attribute) => (
                                        <React.Fragment key={attribute}>
                                            <li> {attribute}:  {selectedMetadataError[attribute] === "" ? "null" : selectedMetadataError[attribute]} </li>
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </Paper>
                            <Box sx={{ paddingTop: '2em' }}></Box>
                        </>
                    )}
                    <Button variant="contained" color="primary" onClick={handleCloseModal} >
                        Cerrar
                    </Button>
                </Box>
            </Modal>
        </>
    );

}

export default IntegrationDataTable