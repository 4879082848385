import { HeadProvider, Title } from 'react-head';
import IntegrationDataTable from '../components/syncedData/IntegrationDataTable';




const SyncedData = () => {
    return (
        <>
            <HeadProvider>
                <Title>Errores Security System</Title> {/* Establecemos el título de la página utilizando el componente Title */}
            </HeadProvider>
            <IntegrationDataTable/>

        </>
    );
};

export default SyncedData;
