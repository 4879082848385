import Box from '@mui/material/Box';
import router from './router';
import theme from './theme';
import { RouterProvider } from 'react-router-dom';

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material';
import { useCookies } from 'react-cookie'
import { useEffect } from 'react';

function App() {
  const [cookies] = useCookies([''])

  // When no poseidon token cookie is found, redirect to poseidon page for login
  useEffect(() => {
    if (!cookies.poseidon_token) {
      window.location.replace(process.env.REACT_APP_POSEIDON_REDIRECT_URL)
    }
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', margin: 0 }}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Box>
  );
}

export default App;