import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#76777A',
    },
    secondary: {
      main: '#000000',
    },
    background1: {
      main: '#DFDFDF',
    },
    background2: {
      main: '#F6F6F6',
    },
    background3: {
      main: '#F9F9F9',
    },
  },
});

export default theme;
