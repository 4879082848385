import { createBrowserRouter } from 'react-router-dom'

import Root from './components/Root'
import SyncedData from './pages/SyncedData'
import Clients from './pages/Clients'

// Crear un enrutador de navegador utilizando la función createBrowserRouter de react-router-dom
const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />, // Componente raíz asociado a la ruta '/'
        children: [
            {
                path: '/',
                element: <Clients />, 
            },
            {
                path: '/syncedData',
                element: <SyncedData />, 
            },
           
        ],
    },
])

export default router
